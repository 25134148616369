import React, { useState, useEffect, Fragment, useRef } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import _ from "lodash"
import { Link } from "gatsby"
import slugify from "slugify"

const makeChild = (tag, index) => {
  return (
    <Fragment key={index}>
      <li key={tag}>
        <Link to={`/${slugify(_.toLower(tag))}`}>{tag}</Link>
      </li>
    </Fragment>
  )
}

const renderMobileNav = groups => {
  const wrapper = useRef(null);
  const [ariaHidden, setAriaHidden] = useState(true);

  const handleClick = event => {
    // console.log("got click INSIDE!");
    setAriaHidden(!ariaHidden);
  };

  const handleClickOutside = event => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      // console.log("got click outside!!");
      setAriaHidden(true);
    };
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    }
  });

  const renderMobileNavItems = (group) => {
    return _.map(group.tagsPlugtrays, makeChild);
  };

  return (
    <li ref={wrapper}>
      <a
        className={`menu toggle ${
          ariaHidden === false ? "open" : "closed"
          }`}
        aria-controls="mobile-menu"
        onClick={handleClick}
      >
        Shop plug trays
      </a>
      <ul className="submenu" id="mobile-menu" aria-hidden={ariaHidden}>
        {_.map(groups, renderMobileNavItems)}
      </ul>
    </li>
  )
};

const renderGroupedProductCategory = group => {
  const wrapper = useRef(null)
  const [ariaHidden, setAriaHidden] = useState(true)

  const handleClick = event => {
    // console.log("got click INSIDE!");
    setAriaHidden(!ariaHidden)
  }

  const handleClickOutside = event => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      // console.log("got click outside!!");
      setAriaHidden(true)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  return (
    <Fragment key={group.name}>
      {group.tagsPlugtrays.length === 1 ? (
        <Fragment>
          <li key={group.tagsPlugtrays[0]}>
            <Link to={`/${slugify(_.toLower(group.tagsPlugtrays[0]))}`}>{group.name}</Link>
          </li>
        </Fragment>
      ) : (
          <li key={group.name} ref={wrapper}>
            <a
              className={`menu toggle ${
                ariaHidden === false ? "open" : "closed"
                }`}
              aria-controls={slugify(_.toLower(group.name))}
              onClick={handleClick}
            >
              {group.name}
            </a>
            <ul
              className="submenu"
              id={slugify(_.toLower(group.name))}
              aria-hidden={ariaHidden}
            >
              {_.map(group.tagsPlugtrays, makeChild)}
            </ul>
          </li>
        )}
    </Fragment>
  )
}

const ProductCategories = () => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            productCategoryGroups {
              name
              tagsPlugtrays
            }
          }
        }
      }
    `}
    render={data => {
      let groups = _.get(data, "site.siteMetadata.productCategoryGroups")

      return (
        <>
          <ul className="dropdown menu show-for-medium">
            {_.map(groups, renderGroupedProductCategory)}
          </ul>
          <ul className="dropdown menu show-for-small-only nav__mobile">
            {renderMobileNav(groups)}
          </ul>
        </>
      )
    }}
  />
)

ProductCategories.propTypes = {
  wrapperElement: PropTypes.string,
}

ProductCategories.defaultProps = {
  wrapperElement: "span",
}

export default ProductCategories
