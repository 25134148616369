import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

import { getCurrentUser } from "../utils/auth"
import ProductCategories from "./products/ProductCategories"
import User from "./user/User"
import logo from "../images/logo.png"
import logo1x from "../images/logo@1x.png"
import logo2x from "../images/logo@2x.png"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      alt="Plug trays from Mountain Valley Growers logo"
    />
  )
}

const Header = () => (
  <div className="header">
    <Link to="/" className="logo">
      <img src={logo1x}
        srcSet={`${logo2x} 2x`}
        alt="Plug trays from Mountain Valley Growers logo"
      />
    </Link>
    <nav>
      <ProductCategories />
    </nav>
  </div>
)

export default Header
