import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { fetchPricesForTag } from '../../utils/public-api';
import { getProductLink } from '../../utils/cart'
import config from '../../../config';

const ENV_CONFIG = config.get();

const Product = (product) => {
  const {
    Id,
    BotanicalName,
    CommonName,
    NamePlugtrays,
    Images,
    PricePlugtrays,
    InStockPlugtrays,
    Slug,
    setCartQuantity,
    cartQuantity
  } = product;

  let image = _.get(Images, 0);
  let renderedImage;

  if (image) {
    renderedImage = `${ENV_CONFIG.productImageCDNPath}${Id}/0-small.jpg`
  } else {
    renderedImage = 'borked'
  }

  let renderedStock;
  if (InStockPlugtrays === 0) {
    renderedStock = <p className="product-thumb--link__notify">Temporarily out of stock &middot; <Link to={getProductLink(product)} className="product-thumb--link__product">Notify me</Link></p>;
  }

  return (
    <div
      key={Id}
      className="cell small-12 medium-6 large-3 product-thumb--card"
    >
      <Link to={getProductLink(product)} className="product-thumb--link__product">
        <div
          className="product-thumb--image"
          style={{
            'backgroundImage': `url("${renderedImage}")`
          }}
        />
        <p className="product-thumb--title">
          <em>{BotanicalName}</em> <br />
          <strong>{CommonName}</strong>
        </p>
        <p className="product-thumb---price">${PricePlugtrays} / tray of 128 plants</p>
      </Link>
      {renderedStock}
    </div>
  );
};

const ProductGrid = ({ staticProducts, Tag, sortBy }) => {
  const [stockData, setStockData] = useState({ loading: true, data: [] });
  const [mergedData, setMergedData] = useState({ loading: true, data: [] });
  // const [cartProducts, setCartProducts] = useState({});
  // const setProductQuantity = (Id, quantity) => setCartProducts(setCartProductQuantity(Id, quantity));

  useEffect(() => {
    const f = async () => {
      const data = await fetchPricesForTag(Tag);
      setStockData({ loading: false, data: data.Products });
    };
    f();
  }, [Tag]);

  useEffect(() => {
    const staticKeyed = _.keyBy(staticProducts, (product) => product.Id);
    const dynamicKeyed = _.keyBy(stockData.data, (product) => product.Id);
    const merged = _.map(staticKeyed, (item, key) => {
      return {
        ...item,
        PricePlugtrays: _.get(dynamicKeyed, [key, 'PricePlugtrays']),
        InStockPlugtrays: _.get(dynamicKeyed, [key, 'InStockPlugtrays']),
        // setCartQuantity: _.partial(setProductQuantity, key),
        // cartQuantity: _.get(cartProducts, key) || 0,
      };
    });
    setMergedData({ loading: false, data: merged });

  }, [staticProducts, stockData]);

  const renderedProducts = _.map(_.sortBy(mergedData.data, [sortBy]), Product);
  return (
    <Fragment>
      {renderedProducts}
    </Fragment>
  );
};

export default ProductGrid;