import _ from 'lodash'
import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";
import Header from "../components/Header"
import ProductGrid from "../components/products/ProductGrid"

export default ({ data, pageContext }) => {
  const { Tag } = pageContext
  const staticProducts = _.filter(data.productsapi.GetProductsWithTagPlugtrays.products, ({ DisplayPlugtrays }) => DisplayPlugtrays);
  const [sortBy, setSortBy] = useState('BotanicalName');

  // filter out the 'plug trays A-F` categories to avoid SEO weirdness
  const SEOTitle = Tag.indexOf('Plug Trays') === 0 ? 'Plug Trays' : Tag;

  const sortByChanged = (e) => {
    const value = e.target.value;
    setSortBy(value);
  }
  const sortByRendered = (
    <>
      <label for="product-grid--sort">Sort by:</label>
      <select id="product-grid--sort" onChange={sortByChanged} value={sortBy}>
        <option value='BotanicalName'>Botanic name</option>
        <option value='CommonName'>Common name</option>
      </select>
    </>);

  return (
    <Layout>
      <SEO title={`Organic ${SEOTitle}`} keywords={`organic ${SEOTitle}`} description={`Certified organic ${SEOTitle} for sale at Mountain Valley Growers`} />
      <Header />
      <div className="grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y">
        <div className="cell small-12 medium-8">
          <h2>{Tag}</h2>
        </div>
        <div className="cell small-12 medium-4">
          {sortByRendered}
        </div>
      </div>
      <div className="grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y">
        <ProductGrid
          Tag={Tag}
          staticProducts={staticProducts}
          sortBy={sortBy}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($Tag: String!) {
    productsapi {
      GetProductsWithTagPlugtrays(Tag: $Tag) {
        products {
          CommonName
          BotanicalName
          Id
          Name
          NamePlugtrays
          Description
          DescriptionPlugtrays
          MVGPath
          Images
          Height
          Hardiness
          FlowerColor
          Characteristics
          Uses
          Tags
          TagsPlugtrays
          Slug
          DisplayPlugtrays
        }
      }
    }
  }
`
